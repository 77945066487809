var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('titles.privacy')))]},proxy:true},(_vm.hasAccess('tournament_policy', 'create'))?{key:"button",fn:function(){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.createUpdateModal",modifiers:{"createUpdateModal":true}}],staticClass:"ml-auto",attrs:{"variant":"success"},on:{"click":function($event){return _vm.$router.push({ name: 'tournament-policy-show' })}}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"d-flex align-items-center justify-content-end mb-2"},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._v(" Per page: "),_c('v-select',{staticClass:"ml-2",staticStyle:{"flex-grow":"1","background-color":"#fff","height":"37px","width":"180px"},attrs:{"options":_vm.perPageList},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}})],1)]),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.fields,"items":_vm.items,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(id)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"cell(images)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-img"},[(item.images && item.images.length > 0)?_c('img',{attrs:{"src":item.images[0].path}}):_c('img',{attrs:{"src":require("@/assets/images/icons/no-photo.png")}})])]}},{key:"cell(description)",fn:function(ref){
var item = ref.item;
return [(item.description)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.checkLocales(item.description))}}):_c('span')]}},{key:"cell(duration_type)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.items[index].duration)+" "+_vm._s(_vm.$t(("titles." + (item.duration_type))))+" ")]}},{key:"cell(is_published)",fn:function(ref){
var item = ref.item;
return [_c('feather-icon',{class:['stroke-current', _vm.generateIconColorToBoolean(item.is_published)],attrs:{"icon":_vm.generateIconToBoolean(item.is_published)}})]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('table-action-btns',{attrs:{"delete-access":{ section: 'tournament_policy', permission: 'delete' },"delete-handler":_vm.deletePolicy,"index":index,"item":item,"update-access":{ section: 'tournament_policy', permission: 'update' }},on:{"openModal":function($event){return _vm.$router.push({
                                name: 'tournament-policy-show',
                                params: { id: item.id }
                            })}}})]}}])})],1),_c('b-row',[_c('b-col',{staticClass:"offset-3 mb-3",attrs:{"cols":"9"}},[(_vm.showPagination)?_c('b-pagination',{staticClass:"float-right mr-4 mb-0",attrs:{"per-page":_vm.pagination.perPage,"total-rows":_vm.pagination.totalItem,"first-number":"","last-number":""},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }